import ComingInline from '../img/comingInline.png';
import ComingOutline from '../img/comingOutline.png';
import FutureInline from '../img/futureInline.png';
import FutureOutline from '../img/futureOutline.png';

export const comingSoonArr = [
	{ name: 'coming soon', imgSrc: ComingInline },
	{ name: 'coming soon', imgSrc: ComingOutline },
	{ name: 'coming soon', imgSrc: ComingInline },
	{ name: 'coming soon', imgSrc: ComingOutline },
	{ name: 'coming soon', imgSrc: ComingInline },
	{ name: 'coming soon', imgSrc: ComingOutline },
	{ name: 'coming soon', imgSrc: ComingInline },
	{ name: 'coming soon', imgSrc: ComingOutline },
	{ name: 'coming soon', imgSrc: ComingInline },
	{ name: 'coming soon', imgSrc: ComingOutline },
	{ name: 'coming soon', imgSrc: ComingInline },
	{ name: 'coming soon', imgSrc: ComingOutline },
];

export const futureArr = [
	{ name: 'future is lie', imgSrc: FutureInline },
	{ name: 'future is lie', imgSrc: FutureOutline },
	{ name: 'future is lie', imgSrc: FutureInline },
	{ name: 'future is lie', imgSrc: FutureOutline },
	{ name: 'future is lie', imgSrc: FutureInline },
	{ name: 'future is lie', imgSrc: FutureOutline },
	{ name: 'future is lie', imgSrc: FutureInline },
	{ name: 'future is lie', imgSrc: FutureOutline },
	{ name: 'future is lie', imgSrc: FutureInline },
	{ name: 'future is lie', imgSrc: FutureOutline },
	{ name: 'future is lie', imgSrc: FutureInline },
	{ name: 'future is lie', imgSrc: FutureOutline },
];
